import { DynamicImage } from 'components/Image';
import classNames from 'classnames';
import styles from './BackgroundImage.module.scss';

export type BackgroundImageProps = {
  containerClassName?: string;
  imageClassName?: string;
  src?: string;
};

export const BackgroundImage = ({
  containerClassName,
  imageClassName,
  src = 'https://www.datocms-assets.com/76629/1695215603-1674481760-background.svg',
}: BackgroundImageProps) => {
  return (
    <span className={classNames(styles.container, containerClassName)} suppressHydrationWarning>
      <DynamicImage
        fill
        src={`${src}`}
        alt={'Logo'}
        loading="lazy"
        decoding="async"
        className={classNames(styles.logo, imageClassName)}
        suppressHydrationWarning
      />
    </span>
  );
};

export type { NeedHelpProps as NeedHelpType } from './NeedHelp';

import React, { memo, useMemo } from 'react';

import { NeedHelpProps } from './NeedHelp';
import dynamic from 'next/dynamic';

export const NeedHelp = memo(function Loader(props: NeedHelpProps): JSX.Element {
  const objFallback = useMemo(() => <div key={'need-help'} style={{ height: `${72}px` }} />, []);

  const Dynamic = useMemo(() => {
    return dynamic<NeedHelpProps>(() => import('components/needHelp/NeedHelp').then((mod) => mod.NeedHelp), {
      ssr: false,
      loading: () => objFallback,
    });
  }, [objFallback]);

  if (Dynamic) return <Dynamic suppressHydrationWarning={true} {...(props as any)} />;

  return objFallback;
});

import { useRouter } from 'next/router';

export type HeaderMenuItemProps = {
  itemClasses: {
    item: string;
    itemActive: string;
    itemDisable: string;
    itemFadeLine: string;
    itemRouterActive: string;
    itemRouteDisable: string;
    itemIsInHome: string;
  };
  id: number | string | null;
  currentId: string;
  isOpenDropdown?: boolean;
  isActiveElement: boolean;
  children: React.ReactNode;
};
export const HeaderMenuItem = ({
  itemClasses,
  id,
  currentId,
  children,
  isOpenDropdown,
  isActiveElement,
}: HeaderMenuItemProps) => {
  const { asPath } = useRouter();
  return (
    <li
      className={`${itemClasses.item} ${
        id === +currentId && isActiveElement ? itemClasses.itemActive : itemClasses.itemDisable
      } ${id === +currentId || isActiveElement ? itemClasses.itemRouterActive : itemClasses.itemRouteDisable} ${
        isOpenDropdown && itemClasses.itemFadeLine
      } ${asPath === '/' && itemClasses.itemIsInHome}`}
      suppressHydrationWarning
    >
      {children}
    </li>
  );
};

import { CardHoverMask } from '../../cardHoverMask';
import { CardNotAvailableImage } from '../../cardNotAvailableImage';
import { CardSpinner } from '../../cardSpinner/CardSpinner';
import { DynamicImage } from 'components/Image';
import { ImageProps } from 'next/image';
import { breakpoint } from '../../../../../utility/constant';
import classNames from 'classnames';
import { getSrcImage } from '../../../utils';
import { isNotEmpty } from 'utility/functions';
import styles from './CardImageContainer.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import { useMemo } from 'react';
import { useTypedSelector } from 'lib/centralStore';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type CardImageContainerProps = {
  image?: ImageProps & {
    srcThumbnail: string;
    srcCover: string;
  };
  children?: React.ReactNode;
  imageClass?: string;
  bgColor?: string;
  playLabel?: string;
  isHover: boolean;
  handleHover?: React.Dispatch<React.SetStateAction<boolean>>;
  showImage?: boolean;
  isCover: boolean;
  hasGamePlatform?: boolean;
  handleLaunchGame: () => void;
  hasSpinner?: boolean;
};

export const CardImageContainer = ({
  image,
  children,
  imageClass,
  bgColor,
  isHover,
  isCover,
  hasGamePlatform,
  showImage = true,
  hasSpinner = false,
  handleHover,
  handleLaunchGame,
}: CardImageContainerProps) => {
  const { doCheck } = useWindowSize();

  const [isLinked] = doCheck([{ comparer: enComparer.less, size: breakpoint.lg }]);

  const labelGioca = useGetLabelByKey('gioca');

  const { srcImage, hasImg } = useMemo(() => {
    const srcImage = (image?.srcThumbnail || image?.srcCover) && getSrcImage(isCover, image);
    const hasImg = isNotEmpty(srcImage);
    return {
      srcImage,
      hasImg,
    };
  }, [isCover, image]);
  const isAuthenticated = useIsAuthenticated();

  if (isLinked) {
    return (
      <button type="button" className={styles.button} onClick={() => handleLaunchGame()}>
        <div
          className={classNames(styles.container, styles.linked, imageClass ?? '')}
          style={{ backgroundColor: bgColor ?? 'transparent' }}
        >
          {hasImg ? (
            <div className={`${styles.imgContainer} ${isAuthenticated && styles.imgHasLinearGradient} `}>
              <DynamicImage
                fill
                src={`${srcImage}`}
                alt={image?.alt ?? ''}
                title={image?.title ?? ''}
                loading="lazy"
                decoding="async"
                className={styles.image}
              />
            </div>
          ) : (
            showImage && <CardNotAvailableImage />
          )}
          {children}
        </div>
      </button>
    );
  }

  return (
    <div
      onMouseEnter={() => handleHover!(true)}
      onMouseLeave={() => handleHover!(false)}
      className={classNames(styles.container, imageClass ?? '')}
      style={{
        backgroundColor: bgColor ?? 'transparent',
      }}
    >
      {hasImg ? (
        <div className={classNames(styles.imgContainer, { [styles.imgHasLinearGradient]: isAuthenticated })}>
          <DynamicImage
            fill
            src={srcImage!}
            alt={image?.alt ?? ''}
            title={image?.title ?? ''}
            loading="lazy"
            decoding="async"
            className={styles.image}
          />
        </div>
      ) : (
        showImage && <CardNotAvailableImage />
      )}
      {hasGamePlatform && !hasSpinner && (
        <CardHoverMask label={labelGioca!} visible={isHover} handleLaunchGame={handleLaunchGame} />
      )}
      {hasSpinner && <CardSpinner />}
      {children}
    </div>
  );
};

import { useEffect, useState } from 'react';

import { breakpoint } from '../utility/constant';
import useWindowSize, { enComparer } from './useWindowSize';

export const useCodeGameByWindowSize = (codeGameDesktop?: string, codeGameMobile?: string) => {
  const [codeGame, setCodeGame] = useState<string>('');

  const { doCheck } = useWindowSize();
  const [isMobile] = doCheck([{ comparer: enComparer.less, size: breakpoint.lg }]);

  useEffect(() => {
    const nextValue = codeGameMobile && isMobile ? codeGameMobile : codeGameDesktop;
    setCodeGame(nextValue ?? '');
  }, [codeGameDesktop, codeGameMobile, isMobile]);

  return [codeGame];
};

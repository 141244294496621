// export { NewsDialog } from './NewsDialog';
// export type { NewsDialogProps as NewsDialogType } from './NewsDialog';

import { DatoCmsModalTutorialType } from 'lib/datoCms/types';
import dynamic from 'next/dynamic';

export interface NewsDialogProps extends DatoCmsModalTutorialType {
  isMobileFullScreen?: boolean;
  hasClickOutside?: boolean;
  onHideModal: (_: boolean) => void;
}
export const NewsDialog = dynamic<NewsDialogProps>(() =>
  import('components/newsDialog/NewsDialog').then((mod) => mod.NewsDialog)
);

import { useCallback, useEffect, useRef, useState } from 'react';

import { AppItem } from 'features/configuration/configurationSlice';
import { isClientSide } from 'utility/functions';
import { selectSmartBanner } from 'features/configuration/selectors';
import { useTypedSelector } from 'lib/centralStore';

const isSSR = isClientSide() ? false : true;

/* eslint-disable no-unused-vars */
export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}
/* eslint-enable no-unused-vars */

const isAndroidBanner = (item: string | AppItem): item is AppItem => {
  return typeof item !== 'string';
};

const DEFAULT_THRESHOLD = 70;
export interface ScrollDirectionProps {
  onScroll?: () => void;
}

export const useScrollDirection = ({ onScroll }: ScrollDirectionProps = {}) => {
  const lastScrollPosition = useRef(0);
  const [scrollDir, setScrollDir] = useState<ScrollDirection | undefined>(undefined);
  const [isTop, setIsTop] = useState<boolean>(true);

  const selectedBanner = useTypedSelector(selectSmartBanner);
  const threshold = selectedBanner && isAndroidBanner(selectedBanner) ? DEFAULT_THRESHOLD + 80 : DEFAULT_THRESHOLD;

  const onScrollHandler = useCallback(() => {
    if (isSSR) return;

    if (typeof onScroll === 'function') {
      onScroll();
    }

    const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
    const nextIsTop = currentScrollTop === 0;
    const nextScrollDir =
      currentScrollTop > lastScrollPosition.current && currentScrollTop > threshold
        ? ScrollDirection.DOWN
        : ScrollDirection.UP;

    if (nextIsTop !== isTop) {
      setIsTop(nextIsTop);
    }
    if (nextScrollDir !== scrollDir) {
      setScrollDir(nextScrollDir);
    }
    lastScrollPosition.current = currentScrollTop;
  }, [isTop, scrollDir, threshold, onScroll]);

  useEffect(() => {
    if (isSSR) return;
    window.addEventListener('scroll', onScrollHandler, { passive: true });
    window.addEventListener('resize', onScrollHandler, { passive: true });

    onScrollHandler();

    return () => {
      if (isSSR) return;
      window.removeEventListener('scroll', onScrollHandler);
      window.removeEventListener('resize', onScrollHandler);
    };
  }, [onScrollHandler]);

  return { scrollDir, isTop };
};

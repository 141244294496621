import { Card, CardProps } from '../components/card/Card';
import { CardImageContainer, CardImageContainerProps } from '../components/card/cardImageContainer/CardImageContainer';
import { CardTextContainer, CardTextContainerProps } from '../components/card/cardTextContainer/CardTextContainer';
import React, { useCallback, useMemo, useState } from 'react';
import { gamePlatforms, launchGame } from 'lib/launchGame/utils';
import { useRetrieveTableInfo, useSubscribeToTable } from 'features/casino/hooks';

import { ActionBarProps } from '../../actionBar/ActionBar';
import { CardBonusChip } from '../components/cardBonusChip';
import { CardExclusiveLabel } from '../components/cardExclusiveLabel';
import { CardFavouriteBtn } from '../components/cardFavouriteBtn';
import { CardImageFooter } from '../components/card/cardImageFooter';
import { CardImageHeader } from '../components/card/cardImageHeader';
import { CardJackpotLabel } from '../components/cardJackpotLabel';
import { CardNewChip } from '../components/cardNewChip';
import { LaunchGameType } from 'lib/launchGame/types';
import { LazyGetTriggerType } from 'features/slotArchive/actions';
import Script from 'next/script';
import { callbackOnAuthenticated } from 'utility/auth';
import { convertInCurrencyValue, isMatch } from 'utility/functions';
import { getNonce } from 'lib/policy';
import { useCodeGameByWindowSize } from 'hooks/useCodeGameByWindowSize';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useInternalSession } from 'hooks/useInternalSession';
import useWindowSize from 'hooks/useWindowSize';

/* eslint-disable no-unused-vars */
export enum GamesCardProduttore {
  Pragmatic = 'pragmatic',
}
/* eslint-enable no-unused-vars */

export type GamesCardProps = CardProps &
  CardImageContainerProps &
  CardTextContainerProps & {
    slug: string;
    promo?: { dataInizio: string; dataFine: string; url: string };
    isFavorite: boolean;
    isExclusive: boolean;
    isNew: boolean;
    jackpot: number;
    jackpotClass?: string;
    hasJackpot: boolean;
    functionality?: string[];
    volatility: {
      value: string;
      label: string;
    };
    isLoggedIn?: boolean;
    children?: React.ReactNode;
    produttore: GamesCardProduttore | string;
    idProduttore: string;
    verticale: string;
    isLoading?: boolean;
    triggerRefetch?: LazyGetTriggerType;
    launchType: string;
    providerId?: string;
  } & Omit<ActionBarProps, 'hasDemo'>;

export const GamesCard = (props: GamesCardProps) => {
  const { isAuthenticated } = useInternalSession();

  const [isHover, setIsHover] = useState<boolean>(false);
  const [injectScript, setInjectScript] = useState<boolean>(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const labelJackpot = useGetLabelByKey('jp');
  const labelInEsclusiva = useGetLabelByKey('in-esclusiva');

  const { session } = useInternalSession();

  const {
    id,
    slug,
    title,
    promo,
    isNew,
    jackpot,
    isCover,
    playInfo,
    gameMode,
    modalita,
    verticale,
    children,
    isInstant,
    hasJackpot,
    produttore,
    isFavorite,
    isExclusive,
    idProduttore,
    jackpotClass,
    containerClass,
    codicePiattaforma,
    triggerRefetch,
    launchType,
    providerId,
  } = props;
  const { name, cardNumber, contractCode } = session?.user ?? {};
  const { width, height, product, codeGameMobile, codeGameDesktop } = playInfo ?? {};

  const gameScriptSrc = Reflect.get(gamePlatforms, codicePiattaforma)?.urlParams?.FIM_CONF;
  const [codeGame] = useCodeGameByWindowSize(codeGameDesktop, codeGameMobile);
  const { isMobile } = useWindowSize();

  const tableInfo = useRetrieveTableInfo(providerId);
  useSubscribeToTable(providerId);

  const image = useMemo(() => {
    const returnObj: typeof props.image = {
      src: tableInfo?.tableImage! ?? props.image?.src!,
      alt: props.image?.alt ?? 'Game Image',
      srcThumbnail: tableInfo?.tableImage! ?? props.image?.srcThumbnail!,
      srcCover: tableInfo?.tableImage! ?? props.image?.srcCover!,
    };
    return returnObj;
  }, [props, tableInfo?.tableImage]);

  const gameType: LaunchGameType = useMemo(() => {
    return {
      isDemo: false,
      product: product as LaunchGameType['product'],
      gameCode: codeGame,
      username: name!,
      gameMode: gameMode?.[0],
      provider: produttore!,
      isInstant,
      modalita,
      dimension: {
        width: width,
        height: height,
      },
      cardNumber: cardNumber!,
      contractCode: contractCode!,
      codicePiattaforma,
      isMobile,
      idProvider: +idProduttore,
      launchType,
    };
  }, [
    product,
    codeGame,
    name,
    gameMode,
    produttore,
    isInstant,
    modalita,
    width,
    height,
    cardNumber,
    contractCode,
    codicePiattaforma,
    isMobile,
    idProduttore,
    launchType,
  ]);

  const handleLaunchGame = useCallback(async () => {
    setInjectScript(true);
    setIsLaunching(true);
    await launchGame(gameType);
    setIsLaunching(false);
  }, [gameType]);

  return (
    <React.Fragment>
      {gameScriptSrc && isMatch(gameScriptSrc, '^http') && injectScript && (
        <Script src={gameScriptSrc} nonce={getNonce()} />
      )}
      <Card containerClass={containerClass}>
        <CardImageContainer
          image={image}
          isHover={isHover}
          isCover={isCover}
          handleHover={setIsHover}
          hasSpinner={isLaunching}
          hasGamePlatform={!!gamePlatforms[codicePiattaforma as keyof typeof gamePlatforms]}
          handleLaunchGame={() => callbackOnAuthenticated(isAuthenticated, handleLaunchGame)}
        >
          <CardImageHeader>
            {promo && promo.url && <CardBonusChip url={promo.url!} />}
            {isNew && <CardNewChip label={'new'} hidden={isHover} />}
          </CardImageHeader>
          <CardImageFooter hidden={isHover}>
            {isExclusive && <CardExclusiveLabel label={labelInEsclusiva!} />}
            {hasJackpot && jackpot >= 0 && jackpot !== null && (
              <CardJackpotLabel
                label={labelJackpot!}
                maxJackpot={convertInCurrencyValue(jackpot / 100)}
                className={jackpotClass}
              />
            )}
          </CardImageFooter>
        </CardImageContainer>
        {isAuthenticated && (
          <CardFavouriteBtn idGame={id!} favourite={isFavorite} verticale={verticale} triggerRefetch={triggerRefetch} />
        )}
        <CardTextContainer title={title!} url={`gioco/${slug}`}>
          {children}
        </CardTextContainer>
      </Card>
    </React.Fragment>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Icon, IconsEnum } from 'components/Icons';
import { getCookie } from 'lib/persist/cookie';
import { DynamicImage } from 'components/Image';
import { isNotEmpty, purgeNulls } from 'utility/functions';
import styles from './InstantPanel.module.scss';
import { useRouter } from 'next/router';

export const ButtonOpenInstantPanel = (props: any) => {
  const { locale } = useRouter();
  const { image, setOpenPanel } = props;
  const [imgOK, setImgOK] = useState(true);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    const get = getCookie('instant-roulette-disabled');
    if (get) {
      setDisabled(true);
    }
  }, []);

  const objToggler = useMemo(() => {
    const { url, alt } = image ?? {};
    if (imgOK && isNotEmpty(url)) {
      return (
        <DynamicImage
          src={url}
          alt={alt ?? ''}
          width={40}
          height={40}
          loading="lazy"
          decoding="async"
          className={styles.instantIcon}
          onError={() => setImgOK(false)}
        />
      );
    } else {
      return <Icon iconId={IconsEnum.INSTANT_PANEL} className={styles.instantIcon} />;
    }
  }, [image, imgOK]);

  return purgeNulls(image) ? (
    <button
      type="button"
      id="openButton"
      disabled={disabled}
      className={classNames(styles.openButton, { [styles.openButtonDisabled]: disabled })}
      onClick={() => {
        setOpenPanel(true);
      }}
    >
      {objToggler}
    </button>
  ) : null;
};

// export type { FooterProps as FooterType } from './Footer';

import { CertificationBlock } from './footerDisclaimer/FooterDisclaimer';
import { Img } from 'utility/types';
import { NavigationBlock } from './footerNavigation/FooterNavigation';
import { SwitchThemeType } from './switchTheme';
import { ThemeLogoType } from 'components/themeLogo';
import dynamic from 'next/dynamic';

export type FooterProps = {
  settingsTitle: string;
  settingsLabelTema: string;
  switchTheme: SwitchThemeType;
  footerNavigation: NavigationBlock[];
  paymentLogoList: ThemeLogoType[];
  ippicaTitle: string;
  ippicaLogoList: Img[];
  certificationsBlock: CertificationBlock[];
  disclaimerParagraph: string;
  warningLogo: string;
  warningText: string;
};

// export { Footer } from './Footer';
export const Footer = dynamic<FooterProps>(() => import('components/footer/Footer').then((mod) => mod.Footer), {
  ssr: false,
});

import { AnchorLink, AnchorLinkType } from '../../anchorLink';
import { Icon, toIconsEnum } from 'components/Icons';
import React, { useEffect, useMemo } from 'react';
import { RootState, useAppDispatch, useTypedSelector } from 'lib/centralStore';

import { DatoCmsImageTheme } from 'lib/datoCms/types';
import { DynamicImage } from 'components/Image';
import { Navigation } from '../Header';
import { NewMessagesIndicator } from 'components/newMessagesIndicator';
import { PersonalAreaModal } from 'components/personalAreaModal';
import { UserMenu } from '../userMenu';
import { breakpoint } from '../../../utility/constant';
import { selectAvatar } from 'features/dashboard/selectors';
import { selectIsActiveAvatars } from 'features/configuration/selectors';
import { setAvatar } from 'features/dashboard/dashboardSlice';
import { setContentLoaded } from 'features/dashboard/snaiPlus/snaiPlusSlice';
import styles from './UserNavigation.module.scss';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useGetMyAvatarQuery } from 'features/api/userSlice';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type UserNavigationProps = {
  id?: string | null;
  allNavigationList: (Navigation | AnchorLinkType)[];
  userNavigationList: Navigation[];
  mobileUserNavigationList: Navigation[];
  promoHref?: string;
  handleShowDetailsMenu: Function;
  showDetail: boolean;
  indexItem: number | string;
  headerHeight?: number;
  logoSnaiPi: DatoCmsImageTheme;
  listFromCMS?: Navigation[];
};
export const UserNavigation = ({
  allNavigationList,
  userNavigationList,
  promoHref,
  indexItem,
  showDetail,
  handleShowDetailsMenu,
  mobileUserNavigationList,
  headerHeight,
  logoSnaiPi,
  listFromCMS,
}: UserNavigationProps) => {
  const dispatch = useAppDispatch();
  const { isOpenPersonalAreaModal } = useSelector((store: RootState) => store.modal);
  const { asPath } = useRouter();
  const avatar = useTypedSelector(selectAvatar);
  const isActiveFeatureAvatars = useTypedSelector(selectIsActiveAvatars);
  const { doCheck } = useWindowSize();

  const [isTablet] = doCheck([{ comparer: enComparer.less, size: breakpoint.lg }]);

  useBlockScroll(isOpenPersonalAreaModal);

  const navigationItemFavorite = useMemo(
    () => userNavigationList.find((item) => (item.icon ? item.icon[0].iconName === 'heart-outline' : undefined)),
    [userNavigationList]
  );
  const navigationItemMessage = useMemo(
    () => userNavigationList.find((item) => (item.icon ? item.icon[0].iconName === 'message' : undefined)),
    [userNavigationList]
  );

  const { data: myAvatar } = useGetMyAvatarQuery(undefined, {
    skip: !isActiveFeatureAvatars,
  });
  useEffect(() => {
    if (!avatar) {
      if (myAvatar && myAvatar.length > 0 && isActiveFeatureAvatars) {
        dispatch(setAvatar(myAvatar[myAvatar.length - 1]));
      } else {
        dispatch(setAvatar(undefined));
      }
    }
  }, [dispatch, myAvatar, avatar, isActiveFeatureAvatars]);

  return (
    <div className={styles.buttonsListContainer}>
      {userNavigationList &&
        userNavigationList.map((el) => {
          const url = `${el?.navigationItemBlock?.[0]?.href ?? promoHref}`.toLowerCase();
          return (
            <React.Fragment key={el.id}>
              {el.label === 'promo' ? (
                <div className={styles.btnLinkContainer}>
                  <AnchorLink
                    href={url}
                    className={`${styles.btnLink} ${asPath.toLowerCase() === url ? styles.isActive : styles.isDisable}`}
                  >
                    <Icon
                      iconId={toIconsEnum(el.icon![0].iconName)}
                      className={styles.icon}
                      color="var(--color-primary)"
                    />
                  </AnchorLink>
                </div>
              ) : el.label !== 'user' ? (
                !isTablet && (
                  <div className={styles.btnLinkContainer}>
                    <AnchorLink
                      href={el.navigationItemBlock![0].href}
                      className={`${styles.btnLink} ${
                        asPath.includes(el.navigationItemBlock![0].href as string) ? styles.isActive : styles.isDisable
                      }`}
                    >
                      <Icon
                        iconId={toIconsEnum(el.icon![0].iconName)}
                        className={styles.icon}
                        color="var(--color-on-bg-primary)"
                      />
                    </AnchorLink>
                    {el.label === 'message' ? <NewMessagesIndicator /> : null}
                  </div>
                )
              ) : (
                <React.Fragment>
                  <div className={styles.btnLinkContainer}>
                    <button
                      aria-expanded={indexItem === el.id && showDetail ? 'true' : 'false'}
                      /* className={styles.buttonItem} */
                      className={`${styles.btnLink} ${
                        // (+indexItem === +el.id && showDetail) ||
                        asPath.includes('dashboard') &&
                        asPath !== navigationItemMessage?.navigationItemBlock![0].href &&
                        asPath !== navigationItemFavorite?.navigationItemBlock![0].href
                          ? styles.isActive
                          : styles.isDisable
                      }`}
                      type="button"
                      onClick={() => {
                        handleShowDetailsMenu(el.id, allNavigationList);
                        dispatch(setContentLoaded(true));
                      }}
                    >
                      {!avatar ? (
                        <Icon
                          iconId={toIconsEnum(el.icon![0].iconName)}
                          className={styles.icon}
                          color="var(--color-on-bg-primary)"
                        />
                      ) : (
                        <DynamicImage
                          src={avatar.src}
                          alt="avatar"
                          width={24}
                          height={24}
                          style={{ borderRadius: '50%' }}
                          loading="lazy"
                          decoding="async"
                        />
                      )}
                    </button>
                  </div>
                  {!isTablet ? (
                    <UserMenu
                      logoSnaiPi={logoSnaiPi}
                      showDetail={indexItem === el.id && showDetail}
                      currentIndex={indexItem}
                      handleShowDetailsMenu={() => handleShowDetailsMenu(el.id, allNavigationList)}
                      buttonList={listFromCMS}
                    />
                  ) : (
                    <PersonalAreaModal
                      isOpen={showDetail}
                      logoSnaiPi={logoSnaiPi}
                      headerHeight={headerHeight}
                      userNavigationList={mobileUserNavigationList}
                      handleShowDetailsMenu={() => handleShowDetailsMenu(el.id, allNavigationList)}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

import { Close, Content, Overlay as DialogOverlay, Portal as DialogPortal, Title } from '@radix-ui/react-dialog';
import { Icon, IconsEnum } from 'components/Icons';

import styles from './DialogContent.module.scss';

export type DialogContentProps = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  isMobileFullScreen?: boolean;
  isClosable?: boolean;
  hasClickOutside?: boolean;
  onCloseClick?: () => void;
  hasHighlightedTitle?: boolean;
  classNameCloseBtn?: string;
};

export const DialogContent = ({
  title,
  children,
  className,
  isMobileFullScreen,
  isClosable,
  hasClickOutside,
  onCloseClick,
  hasHighlightedTitle,
  classNameCloseBtn,
}: DialogContentProps) => {
  const preventClose = (event: {} & { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <DialogPortal>
      <DialogOverlay className={styles.overlay} />
      <Content
        onEscapeKeyDown={(event) => {
          if (!isClosable) {
            preventClose(event);
          }
        }}
        onInteractOutside={(event) => {
          if (!isClosable || !hasClickOutside) {
            preventClose(event);
          }
        }}
        className={`${styles.content}  ${isMobileFullScreen ? styles.mobileFullScreen : ''} ${
          hasHighlightedTitle ? styles.withFullHeader : ''
        } ${className ? className : ''}`}
      >
        <div className={`${styles.header} ${classNameCloseBtn ? classNameCloseBtn : ''}`}>
          {title && <Title className="DialogTitle">{title}</Title>}

          {isClosable && (
            <Close asChild>
              <button className={styles.closeBtn} aria-label="Close" onClick={() => onCloseClick && onCloseClick()}>
                <Icon className={styles.icon} iconId={IconsEnum.CLOSE} color="var(--color-on-bg-primary)" />
              </button>
            </Close>
          )}
        </div>

        {children}
      </Content>
    </DialogPortal>
  );
};

import { selectSignalRConnection } from 'features/signalR/selectors';
import { SignalRs } from 'features/signalR/signalRSlice';
import { useTypedSelector } from 'lib/centralStore';
import { useEffect } from 'react';

type TableSubscription = {
  type: 'subscribe';
  key: string;
  casinoId: string;
  currency: 'EUR';
};

export const useSubscribeToTable = (tableId?: string) => {
  const connection = useTypedSelector((state) => selectSignalRConnection(state)(SignalRs.casino)) as WebSocket;

  const subscriptionJson = JSON.stringify({
    casinoId: process.env.NEXT_PUBLIC_CASINO_LIVE_ID,
    currency: 'EUR',
    key: tableId,
    type: 'subscribe',
  } as TableSubscription);

  useEffect(() => {
    if (tableId && connection && connection.readyState === WebSocket.OPEN) {
      connection.send(subscriptionJson);
    }
  }, [connection, subscriptionJson, tableId]);
};

export const useRetrieveTableInfo = (tableId?: string) => {
  return useTypedSelector((state) => (tableId ? state.casino.liveTables[tableId] : undefined));
};

import { Icon, IconsEnum } from '../../Icons';

import { AnchorLink } from '../../anchorLink';
import { DynamicImage } from 'components/Image';
import { Img } from '../../../utility/types';
import { LinkProps } from 'next/link';
import { isNotEmpty } from 'utility/functions';
import styles from './Disclaimer.module.scss';
import { DatoCmsImage } from 'sites/happybet/lib/datoCms/types';
import classNames from 'classnames';

export type DisclaimerProps = {
  description: string;
  image: DatoCmsImage;
  anchorlink: LinkProps;
  chancesLabel: string;
  chancesLink: LinkProps;
  closeDisclaimer?: () => void;
  customClassName?: string;
};

export const Disclaimer: React.FC<DisclaimerProps> = ({
  description,
  image,
  anchorlink,
  chancesLabel,
  chancesLink,
  closeDisclaimer,
  customClassName,
}: DisclaimerProps) => {
  return (
    <section className={classNames(styles.container, customClassName)}>
      <div className={styles.wrapper}>
        <AnchorLink href={anchorlink.href} className={styles.imgContainer}>
          {isNotEmpty(image?.url) && (
            <DynamicImage
              src={`${image.src}`}
              alt={image.alt ?? ''}
              fill
              loading="lazy"
              decoding="async"
              className={styles.img}
            />
          )}
        </AnchorLink>
        <p className={styles.text}>{description}</p>
      </div>

      <AnchorLink href={chancesLink.href} className={styles.link} target="_blank">
        {chancesLabel}
      </AnchorLink>
      {closeDisclaimer && (
        <button
          type="button"
          aria-label={IconsEnum.CLOSE}
          aria-expanded="false"
          className={styles.btnClose}
          onClick={closeDisclaimer}
        >
          <Icon iconId={IconsEnum.CLOSE} color="var(--color-neutral-01)" className={styles.iconClose} />
        </button>
      )}
    </section>
  );
};

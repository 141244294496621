import { AnchorLink, AnchorLinkType } from '../anchorLink';
import { Icon, toIconsEnum } from 'components/Icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollDirection, useScrollDirection } from 'hooks/useScrollDirection';
import { SessionState, useInternalSession } from 'hooks/useInternalSession';
import { isMatch, isTruthy } from 'utility/functions';

import { AccordionMenu } from './accordionMenu';
import { DatoCmsDisclaimer, DatoCmsImageTheme } from 'lib/datoCms/types';
import { HeaderHamburgerButton } from './headerHambugerButton';
import { HeaderNavigation } from './headerNavigation';
import { ThemeLogo } from 'components/themeLogo';
import type { ThemeLogoType } from 'components/themeLogo';
import { UserNavigation } from './userNavigation';
import classNames from 'classnames';
import { setIsOpenLoginModalValue } from '../../features/modal/modalSlice';
import styles from './Header.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useGetLabelByKey } from 'hooks/useLingUI';
import useOutsideAlerter from 'hooks/useClickOutside';
import { Disclaimer } from './disclaimer';
import { useRouter } from 'next/router';
import { ApiStatus } from 'features/api/thunkUtils';

export type HeaderProps = {
  onlyLogo?: boolean;
  promoHref?: string;
  logoBrand?: ThemeLogoType;
  logoSnaiPi: DatoCmsImageTheme;
  profileHref?: string;
  navigationList?: (Navigation | AnchorLinkType)[];
  snaiMessageHref?: string;
  userNavigationList?: Navigation[];
  mobileUserNavigationList?: Navigation[];
  userPanel?: Navigation[];
  disclaimer?: DatoCmsDisclaimer;
};

type NavigationItemBlock1 = DetailMenu & AnchorLinkType;

export type Navigation = {
  id: string;
  label: string;
  icon?: { iconName: string }[];
  navigationItemBlock?: NavigationItemBlock1[];
};
export type DetailMenu = {
  id: string;
  menuItemBlock?: MenuItemBlock[];
};
export type MenuItemBlock = {
  linkBlock: AnchorLinkType[];
  label: string;
  id: string;
};

export const Header = ({
  onlyLogo,
  promoHref,
  logoBrand,
  logoSnaiPi,
  navigationList,
  userNavigationList,
  mobileUserNavigationList,
  userPanel,
  disclaimer,
}: HeaderProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [id, setId] = useState<number | string | null>(null);
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [indexItem, setIndexItem] = useState<number | string>(0);
  const headerRef = useRef<HTMLElement>(null);
  const labelAccedi = useGetLabelByKey('login')!;
  const dispatch = useAppDispatch();
  const { pathname, isReady } = useRouter();

  const isHomePage = pathname === '/home';

  const { isAuthenticated, status, apiStatus } = useInternalSession();
  const allNavigationList = [, ...(navigationList ?? []), ...(userNavigationList ?? [])].filter((x) => !!x) as Array<
    Navigation | AnchorLinkType
  >;

  useBlockScroll(menuIsOpen);
  const { scrollDir, isTop } = useScrollDirection();

  const handleShowDetailsMenu = useCallback(
    // (itemId: string | null, array: Navigation[]) => {
    (itemId: number | string, array: Navigation[]) => {
      const searchObject = array.find((item) => item.id === itemId);
      searchObject && setIndexItem(searchObject?.id);
      setId((prevState) => {
        if (prevState && prevState === itemId) {
          setShowDetail(false);
          return null;
        } else {
          setShowDetail(true);
          return itemId;
        }
      });
    },
    [setIndexItem, setId, setShowDetail]
  );

  useOutsideAlerter({
    ref: headerRef,
    onClickOutside: () => {
      setShowDetail(false);
      setId(null);
    },
  });

  const handleMenuOpen = (): void => {
    setMenuIsOpen((prevState) => !prevState);
  };

  const objLogo = useMemo(() => {
    const { alt, title, src, srcDarkMode, redirectUrlLogo } = logoBrand ?? {};

    return (
      <AnchorLink href={redirectUrlLogo ?? '/'} className={styles.logoContainer}>
        {src && <ThemeLogo alt={alt ?? ''} src={src} title={title} priority srcDarkMode={srcDarkMode} fill />}
      </AnchorLink>
    );
  }, [logoBrand]);

  const objButtonsContent = useMemo(() => {
    if (isAuthenticated) {
      return (
        <UserNavigation
          key={`nav-${status}-${apiStatus}`}
          indexItem={indexItem}
          promoHref={promoHref}
          showDetail={showDetail}
          logoSnaiPi={logoSnaiPi}
          listFromCMS={userPanel}
          headerHeight={headerRef?.current?.getBoundingClientRect()?.height ?? 30}
          allNavigationList={allNavigationList}
          userNavigationList={userNavigationList ?? []}
          mobileUserNavigationList={mobileUserNavigationList ?? []}
          handleShowDetailsMenu={handleShowDetailsMenu}
        />
      );
    }

    const buttons = (userNavigationList ?? [])
      .filter((el) => isMatch(el.label, '^promo$'))
      .map((el) => (
        <AnchorLink
          key={`promo-${el.id}-${apiStatus}`}
          href={`${el?.navigationItemBlock?.[0]?.href ?? promoHref}`}
          className={styles.btnLink}
        >
          <Icon
            color="var(--color-primary)"
            iconId={toIconsEnum(el.icon![0].iconName)}
            className={styles.icon}
            suppressHydrationWarning
          />
        </AnchorLink>
      ));

    buttons.push(
      <button
        key={`btn-login-${apiStatus}`}
        type="button"
        className={styles.btnLogin}
        suppressHydrationWarning
        onClick={() => dispatch(setIsOpenLoginModalValue(true))}
      >
        {labelAccedi}
      </button>
    );

    return <React.Fragment key={`btns-${status}`}>{buttons}</React.Fragment>;
  }, [
    status,
    indexItem,
    promoHref,
    userPanel,
    showDetail,
    logoSnaiPi,
    labelAccedi,
    isAuthenticated,
    allNavigationList,
    userNavigationList,
    mobileUserNavigationList,
    dispatch,
    handleShowDetailsMenu,
  ]);

  if (isTruthy(onlyLogo)) {
    return (
      <header
        ref={headerRef}
        className={classNames(styles.container, {
          [styles.scrolledUp]: scrollDir === ScrollDirection.UP && !isTop,
          [styles.scrolledDown]: scrollDir === ScrollDirection.DOWN && !isTop,
        })}
      >
        <section className={styles.headerOnlyLogo}>
          <div className={styles.wrapperOnlyLogo}>{objLogo}</div>
        </section>
      </header>
    );
  }

  return (
    <header
      ref={headerRef}
      className={classNames(styles.container, {
        [styles.scrolledUp]: scrollDir === ScrollDirection.UP && !isTop,
        [styles.scrolledDown]: scrollDir === ScrollDirection.DOWN && !isTop,
      })}
    >
      {disclaimer && isHomePage && (
        <Disclaimer
          description={disclaimer.description}
          image={disclaimer.image}
          anchorlink={disclaimer.anchorlink[0]}
          chancesLabel={disclaimer.chancesLabel}
          chancesLink={disclaimer.chancesOfWinningLink[0]}
          customClassName={classNames(styles.disclaimer)}
        />
      )}
      <section className={styles.header}>
        <div className={styles.wrapper}>
          <div className={styles.firstCol}>
            <HeaderHamburgerButton
              menuIsOpen={menuIsOpen}
              iconContaienrClass={styles.menuIconContainer}
              iconClass={styles.menuIcon}
              handleMenuOpen={handleMenuOpen}
            />
            {objLogo}
            <HeaderNavigation
              id={id}
              indexItem={indexItem}
              showDetail={showDetail}
              navigation={navigationList}
              allNavigationList={allNavigationList}
              handleShowDetailsMenu={handleShowDetailsMenu}
            />
          </div>
          <div
            className={styles.buttonsListContainer}
            data-visible={isReady && apiStatus === ApiStatus.idle ? 1 : 0}
            suppressHydrationWarning
          >
            {objButtonsContent}
          </div>
        </div>
      </section>
      <AccordionMenu
        data={navigationList ?? []}
        isDefault={isDefault}
        setIsDefault={setIsDefault}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
      />
    </header>
  );
};

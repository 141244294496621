import { Icon, IconsEnum } from 'components/Icons';

import { DynamicImage } from 'components/Image';
import { isNotEmpty } from 'utility/functions';
import { selectSmartBanner } from 'features/configuration/selectors';
import styles from './SmartBanner.module.scss';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'lib/centralStore';
import { addHours, isAfter } from 'date-fns';
import useAgentDetect from 'hooks/useAgentDetect';

type SmartBannerProps = {};

export const SmartBanner = ({}: SmartBannerProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const selectedBanner = useTypedSelector(selectSmartBanner);
  const { isIos } = useAgentDetect();

  const router = useRouter();

  useEffect(() => {
    const lastClosed = localStorage.getItem('bannerClosedAt');

    if (lastClosed) {
      const lastClosedTime = new Date(parseInt(lastClosed, 10));
      const expirationTime = addHours(lastClosedTime, 24); // add 24 hours
      if (isAfter(new Date(), expirationTime)) {
        setIsVisible(true);
      }
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('bannerClosedAt', Date.now().toString());
    setIsVisible(false);
  };

  const handleRedirect = async () => {
    if (isIos && selectedBanner?.hrefIos) {
      window.location.href = selectedBanner.hrefIos;
    } else router.push('/le-nostre-app');
  };

  if (!isVisible || !selectedBanner) return;

  return (
    <div className={styles.container}>
      <div>
        <button className={styles.closeIcon} onClick={handleClose}>
          <Icon iconId={IconsEnum.CLOSE} />
        </button>
        {isNotEmpty(selectedBanner?.logoUrl) && (
          <DynamicImage
            src={`${selectedBanner?.logoUrl}`}
            alt={selectedBanner?.title ?? ''}
            width={64}
            height={64}
            loading="lazy"
            decoding="async"
            className={styles.logo}
          />
        )}
        <p className={styles.title}>
          <span>{selectedBanner.title}</span>
          <span>{selectedBanner.description}</span>
        </p>
      </div>
      <div>
        {/* TODO: RETRIEVES PATH FROM CMS */}
        <button className={styles.downloadButton} onClick={handleRedirect}>
          vedi
        </button>
      </div>
    </div>
  );
};

import { DialogContent } from './dialogContent/DialogContent';
import { Root as DialogRoot } from '@radix-ui/react-dialog';
import { DialogTrigger } from './dialogTrigger/DialogTrigger';
import styles from './Dialog.module.scss';

export type DialogProps = {
  open?: boolean;
  children: React.ReactNode;
  isClosable?: boolean;
  hasTrigger?: boolean;
  dialogTitle?: string;
  triggerText?: string;
  hasClickOutside?: boolean;
  triggerClassName?: string;
  contentClassName?: string;
  isMobileFullScreen?: boolean;
  hasHighlightedTitle?: boolean;
  classNameCloseBtn?: string;
  onCloseClick?: () => void;
  onOpenChange?: (_open: boolean) => void;
};

export const Dialog = ({
  open,
  children,
  hasTrigger,
  isClosable = true,
  dialogTitle,
  triggerText,
  hasClickOutside,
  triggerClassName,
  contentClassName,
  classNameCloseBtn,
  isMobileFullScreen,
  hasHighlightedTitle,
  onCloseClick,
  onOpenChange,
}: DialogProps) => {
  if (open) {
    return (
      <DialogRoot open={open} onOpenChange={onOpenChange} modal={true}>
        {hasTrigger && (
          <DialogTrigger>
            <button type="button" className={`${styles.button} ${triggerClassName && triggerClassName}`}>
              {triggerText && triggerText}
            </button>
          </DialogTrigger>
        )}
        <DialogContent
          isMobileFullScreen={isMobileFullScreen}
          title={dialogTitle}
          className={contentClassName ? contentClassName : ''}
          isClosable={isClosable}
          onCloseClick={onCloseClick}
          hasClickOutside={hasClickOutside}
          hasHighlightedTitle={hasHighlightedTitle}
          classNameCloseBtn={classNameCloseBtn}
        >
          {children}
        </DialogContent>
      </DialogRoot>
    );
  }
  return null;
};

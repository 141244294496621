import { Icon, IconsEnum } from 'components/Icons';
import React, { useRef } from 'react';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';

import { breakpoint } from 'utility/constant';
import { getNonce } from 'lib/policy';
import { selectIsStickyHeader } from 'components/header/selectors';
import { setSnaiPlusOpen } from 'features/dashboard/snaiPlus/snaiPlusSlice';
import styles from './SnaiPlusFrame.module.scss';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useInternalSession } from 'hooks/useInternalSession';
import useOutsideAlerter from 'hooks/useClickOutside';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type SnaiPlusFrameProps = {};

export const SnaiPlusFrame = ({}: SnaiPlusFrameProps) => {
  const headerIsSticky = useTypedSelector(selectIsStickyHeader);
  const { snaiPlusOpen, contentLoaded } = useTypedSelector((root) => root?.snaiPlus) ?? {};
  const { session } = useInternalSession();
  const dispatch = useAppDispatch();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useBlockScroll(snaiPlusOpen);

  useOutsideAlerter({
    ref: wrapperRef,
    onClickOutside: () => {
      dispatch(setSnaiPlusOpen(false));
    },
  });

  const closeCaptainUp = () => {
    dispatch(setSnaiPlusOpen(false));
  };

  const { width, doCheck } = useWindowSize();
  const isMobile = doCheck([{ size: breakpoint.sm, comparer: enComparer.less | enComparer.equal }]);

  return contentLoaded ? (
    <div
      className={`${styles.container} ${snaiPlusOpen ? styles.isOpen : ''} ${headerIsSticky ? styles.sticky : ''}`}
      ref={wrapperRef}
    >
      {session?.user?.token && (
        <React.Fragment>
          {isMobile[0] && (
            <div className={styles.close}>
              <button onClick={closeCaptainUp}>
                <Icon iconId={IconsEnum.CLOSE} />
              </button>
            </div>
          )}
          <iframe
            className={styles.iframe}
            src={`${process.env.NEXT_PUBLIC_SNAI_PLUS_URL}?tk=${session?.user.token}&nc=${session?.user.cardNumber}&active_cu=1&from=1`}
            name="TheGame"
            style={isMobile[0] ? { width: `${width}px`, height: 'calc(100% - 12.6rem)' } : { width: '100%' }}
            height={'100%'}
            seamless={true}
            nonce={getNonce()}
          ></iframe>
        </React.Fragment>
      )}
    </div>
  ) : (
    <React.Fragment />
  );
};

import { IS_LOGGED, TOKEN_BASE_NAME, TOKEN_BASE_REFRESH } from 'utility/constant';
import { NextApiRequest, NextApiResponse } from 'next';
import { hasValue, isPersonalUrl, isTruthy } from 'utility/functions';

import { AuthTokenType } from 'features/auth/types';
import { TokenUtilsType } from 'lib/auth/web';
import { UsersGetProfileResponse } from 'types/swagger';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { store } from 'sites/snai/lib/store';
import { utcNow } from 'utility/date';

export const pushCookies = (res: NextApiResponse, token: AuthTokenType | undefined): void => {
  const { accessToken, ...attributes } = token ?? {};
  res.setHeader('Set-Cookie', [
    `${TOKEN_BASE_NAME}=${accessToken}; Path=/; Secure`,
    `${TOKEN_BASE_REFRESH}=${JSON.stringify(attributes)}; Path=/; Secure`,
  ]);
};

export const raiseForbidden = (response: NextApiResponse) => {
  response.status(403);
  response.send({ error: 'RefreshToken missing or invalid' });
};

export const doTokenCheck = async (request: NextApiRequest): Promise<TokenUtilsType> => {
  const pong = utcNow().getTime();

  let expireDate = pong - 1000;

  let missingAccessToken = true;
  let missingExpireDate = true;
  let hasRefreshToken = false;
  let hasLoggedFlag = false;

  let isAuthenticated = false;

  try {
    hasLoggedFlag = isTruthy(request.cookies?.[IS_LOGGED]);

    const srcAccess = request.cookies?.[TOKEN_BASE_NAME];
    missingAccessToken = isTruthy(srcAccess?.length) ? false : true;

    const { expireDate: srcExpire, refreshToken: srcRefresh } = JSON.parse(
      request.cookies?.[TOKEN_BASE_REFRESH] ?? '{}'
    ) as AuthTokenType;
    missingExpireDate = hasValue(srcExpire) ? false : true;
    hasRefreshToken = isTruthy(srcRefresh?.length);

    expireDate = srcExpire ?? expireDate;
  } catch (e) {
    console.error(e);
    expireDate = pong - 1000;
  } finally {
    isAuthenticated = hasRefreshToken && hasLoggedFlag;
  }
  const threshold = Math.floor((expireDate - pong) * 0.001);

  if (missingAccessToken || missingExpireDate || pong > expireDate) {
    if (isAuthenticated) {
      // notify client to refresh session
      return Promise.resolve({ status: 203, threshold, isAuthenticated });
    } else if (isPersonalUrl(request?.headers?.referer ?? '')) {
      // unable to proceede - unathorized
      return Promise.resolve({ status: 403, threshold, isAuthenticated });
    } else {
      return Promise.resolve({ status: 203, threshold, isAuthenticated });
      // access get unautheticated token
    }
  }

  console.log(`TOKEN
   - threshold: ${threshold}"    |    auth: ${isAuthenticated}
   -   expires: ${new Date(expireDate).toISOString()}
   -    now is: ${new Date(pong).toISOString()}`);

  return Promise.resolve({ status: 200, threshold, isAuthenticated });
};

export const callbackOnAuthenticated = <T>(isAuthenticated: boolean, callback: () => T) => {
  isAuthenticated ? callback() : store.dispatch(setIsOpenLoginModalValue(true));
};

export const editUrlWithSessionParam = (url: string, profile: UsersGetProfileResponse) => {
  if (!url.includes('?')) return url;

  const urlParams = new URLSearchParams(url);
  for (const [key, value] of urlParams.entries()) {
    if (value.includes('__')) {
      const decryptedValue = value.replaceAll('__', '');
      urlParams.set(key, profile[decryptedValue]);
    }
  }
  return decodeURIComponent(urlParams.toString());
};

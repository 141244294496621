import { Dispatch, SetStateAction } from 'react';

import { ThemeLogoType } from 'components/themeLogo';
import dynamic from 'next/dynamic';

export type LoginModalProps = {
  isOpen?: boolean;
  logoBrand?: ThemeLogoType;
  handleClose: Dispatch<SetStateAction<boolean>>;
};

export const LoginModal = dynamic<LoginModalProps>(
  () => import('components/modals/loginModal/LoginModal').then((mod) => mod.LoginModal),
  { ssr: false }
);
